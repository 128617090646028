import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-addicio',
  templateUrl: './addicio.component.html',
  styleUrls: ['./addicio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddicioComponent implements OnInit {
  /**
   * Add
   */
  add!: string;

  /**
   * Year
   */
  year!: number;

  /**
   * Age
   */
  age!: number;

  /**
   * Age form
   */
  ageForm!: FormControl;

  /**
   * Show results
   */
  showResults = false;

  /**
   * AddicioComponent
   */
  constructor(private cdr: ChangeDetectorRef) {
  }

  /**
   * Initialize resources for this component
   */
  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.ageForm = new FormControl(null,
      Validators.compose([Validators.required, Validators.min(this.year - 120), Validators.max(this.year)]));
  }

  /**
   * Calculate add
   */
  calc(): void {
    this.showResults = this.ageForm.valid;

    this.age = this.year - this.ageForm.value;

    if (this.age >= 70) {
      this.add = '3.00';
    } else if (this.age >= 67) {
      this.add = '2.75';
    } else if (this.age >= 63) {
      this.add = '2.50';
    } else if (this.age >= 58) {
      this.add = '2.25';
    } else if (this.age >= 54) {
      this.add = '2.00';
    } else if (this.age >= 51) {
      this.add = '1.75';
    } else if (this.age >= 49) {
      this.add = '1.50';
    } else if (this.age >= 47) {
      this.add = '1.25';
    } else if (this.age >= 44) {
      this.add = '1.00';
    } else if (this.age >= 40) {
      this.add = '0.75';
    } else if (this.age < 40) {
      this.add = '0.00';
    }
    this.cdr.detectChanges();
  }
}
