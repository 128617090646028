<div class="page-wrapper">
  <div class="logo" [routerLink]="['']"></div>
  <div class="form-wrapper">
    <form>
      <h1 class="form-title">Addíciószámítás</h1>
      <label for="yearOfBirth">Születési év</label>
      <input type="number" [formControl]="ageForm" id="yearOfBirth" placeholder="Születési év">
      <button type="submit" class="submit-button" [disabled]="!ageForm.valid" (click)="calc()">Számítás</button>
      <div *ngIf="showResults" class="results">
        <h3 class="description">Életkor:</h3>
        <h4 class="data">{{ (age ? age + ' év' : '') }}</h4>
        <h3 class="description">Ajánlott addíciós érték:</h3>
        <h4 class="data">{{ (add ? add + ' D' : '') }}</h4>
      </div>
    </form>
  </div>
</div>

