import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddicioComponent } from './pages/addicio/addicio.component';
import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'addicio', component: AddicioComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
