import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddicioComponent } from './addicio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    AddicioComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ]
})
export class AddicioModule { }
